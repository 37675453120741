import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';

import Typed                from 'typed.js';
import classnames           from 'classnames';
import delay                from 'lodash/delay';

import Polaroid             from '../components/Polaroid';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAnimation: false,
      splashDone: false
    };

    this.onTypingDone = this.onTypingDone.bind(this);
    this.doTypingAnimation = this.doTypingAnimation.bind(this);
  }

  componentDidMount() {

    setTimeout(() => {
      window.scrollTo(0,0);
    }, 1);

    // check whether the typing animation has already run
    // if it has set the splashDone flag to true so that it doesn't play again and exit
    if(sessionStorage.getItem('splash') === 'done') {
      this.setState({ splashDone: true });
      return;
    }

    delay(() => {
      this.doTypingAnimation();
    }, 800);
  }

  componentWillUnmount() {
  	// Make sure to destroy Typed instance on unmounting to prevent memory leaks
    if(this.typed) {
      this.typed.destroy();
    }
  }

  doTypingAnimation() {

    const options = {
      strings: ["I polsh", "I polish pixels..."],
      typeSpeed: 50,
      backSpeed: 30,
      startDelay: 700,
      smartBackspace: true,
      onComplete: (self) => {
        this.onTypingDone();
      }
    };

    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.typewriter, options);
  }

  // set the typinigDone flag to true and write it into sessionStorage
  onTypingDone = () => {
    sessionStorage.setItem('splash', 'done');
    this.setState({
      showAnimation: true
    });
  }

  render() {

    const portfolioData = this.props.data;

    // the three polaroids under the splash
    const slides = portfolioData.slice(0, 3).map(piece => {
      return (
        <Polaroid
          key={piece.id}
          url={piece.url}
          image={piece.image}
          title={piece.title}
          category={piece.category} />
      )
    });

    const { splashDone, showAnimation } = this.state;
    const typewriterText = splashDone && "I polish pixels";

    return (

      <div className="wrapper" data-bg="blusher-grad">
        <Helmet>
          <title>Pixel polisher | Illustrator &amp; front-end developer</title>
          <meta name="description" content="Hello, I'm Bart Hendrix, an illustrator and front-end web developer based in Amsterdam." />
        </Helmet>
        <section
          className={classnames({ 'splash': true, 'splash--animate': showAnimation, 'splash--done': splashDone })}
          data-bg="white-grad">
          <div className="splash__screen">
      			<h2 className="splash__header">
              <span className="splash__typewriter" ref={el => this.typewriter = el}>{typewriterText}</span>
            </h2>
      			<p>
      				Illustrator &amp; front-end developer with a sharp eye for detail.
      				I colour, code and polish pixels.
      			</p>
      			<Link className="splash__cta button" to="/portfolio">view portfolio</Link>
      		</div>
          <SplashBtn>portfolio</SplashBtn>
      		<SplashBtn>about</SplashBtn>
          <SplashBtn>contact</SplashBtn>

      		<span className="splash__disclaimer">drawn by me</span>
      	</section>

        <section data-bg="blusher">
          <div className="polaroids">
            <h3 className="polaroids__header">Recent work</h3>
            { slides }
          </div>
        </section>
      </div>
    );
  }
}

class SplashBtn extends Component {
  render(){
    const {
      children
    } = this.props;

    return (
      <Link className={`splash__btn splash__btn--${children}`} to={`/${children}`}>
        <span className={`splash__writing splash__writing--${children}`}>
          {children}
        </span>
      </Link>
    )
  }
}

export default Home;
