import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { isTouchDevice, supportsResizeObserver, supportsMutationObserver } from '../utils';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    const classPrefix = 'nav';
    const container = this.container.current;
    const items = container.querySelectorAll(`.${classPrefix}__item`);
    const tracker = container.querySelector(`.${classPrefix}__tracker`);
    const isSearching = `${classPrefix}--searching`;

    let itemWidths = [];
    let itemOffsets = [];
    let activeItem = 0;

    if(isTouchDevice() || !supportsResizeObserver() || !supportsMutationObserver()) {
      return;
    }

    addListeners();
    updateAfterResize();

    function addListeners () {
      container.addEventListener('mouseenter', () => {
        container.classList.add(isSearching);
      });

      container.addEventListener('mouseleave', () => {
        setTimeout(() => {
          updateTracker(itemOffsets[activeItem], itemWidths[activeItem]);
          container.classList.remove(isSearching);
        }, 100);
      });

      items.forEach((item, index) => {
        const link = item.querySelector(`.${classPrefix}__link`);

        item.addEventListener('mouseover', () => {
          const itemWidth = itemWidths[index];
          const itemOffset = itemOffsets[index];
          tracker.setAttribute('style', `left: ${itemOffset}px; width: ${itemWidth}px`);
        });

        function callback(mutationsList, observer) {
          mutationsList.forEach(mutation => {
            if (mutation.attributeName === 'class') {
              if(link.classList.contains(`${classPrefix}__link--here`)) {
                activeItem = index;
                updateTracker(itemOffsets[activeItem], itemWidths[activeItem]);
              }
            }
          });
        }

        const mutationObserver = new MutationObserver(callback)
        mutationObserver.observe(link, { attributes: true });
      });
    }

    function updateNav () {
      itemWidths = [];
      itemOffsets = [];
      activeItem = 0;
      container.classList.add(`${classPrefix}--initializing`);

      items.forEach((item, index) => {
        const link = item.querySelector(`.${classPrefix}__link`);
        const itemWidth = Math.round(item.offsetWidth);
        const itemOffset = Math.round(item.offsetLeft);

        itemWidths.push(itemWidth);
        itemOffsets.push(itemOffset);

        if(link.classList.contains(`${classPrefix}__link--here`)) {
          activeItem = index;
          updateTracker(itemOffset, itemWidth);
        }

        setTimeout(() => {
          container.classList.remove(`${classPrefix}--initializing`);
          container.classList.add(`${classPrefix}--initialized`);
        }, 1);
      });
    }

    function updateAfterResize () {
      let containerWidth = 0;

      const resizeObserver = new ResizeObserver(() => {
        const updatedWidth = container.offsetWidth;

        if(containerWidth === updatedWidth) {
          return;
        }

        containerWidth = updatedWidth;
        updateNav();
      });
      resizeObserver.observe(container);
    }

    function updateTracker(offset, width) {
      tracker.style.cssText =`
        left: ${offset}px;
        width: ${width}px
      `;
    }
  }

  render() {
    const {
      closeMenu,
      toggleSubnav,
      subnavActive
    } = this.props;

    return(
      <nav className="nav header__nav app__nav" ref={this.container}>
        <ul className={classnames({ 'nav__list': true, 'nav__list--subnav-active': subnavActive })}>
        	<NavItem onClick={closeMenu} isExact to="home" />
          <NavItem onClick={closeMenu} to="portfolio" />
          <NavItem onClick={closeMenu} to="about" />
          <NavItem onClick={closeMenu} hasSubMenu="true" to="bike trip" toggleSubnav={toggleSubnav}>
            <ul className={classnames({
              'nav__subnav': true,
              'subnav': true,
              'subnav--active': subnavActive })}>
              <li><span className="subnav__toggler" onClick={toggleSubnav}>Back to main</span></li>
              <NavItem onClick={closeMenu} isExact to="/bike-trip" type="subnav" title="world map" />
              <NavItem onClick={closeMenu} to="/bike-trip/pictures" type="subnav" title="pictures" />
              <NavItem onClick={closeMenu} isExact to="/bike-trip/gear" type="subnav" title="gear" />
            </ul>
          </NavItem>
          <NavItem onClick={closeMenu} to="contact" />
        </ul>
        <span className="nav__tracker" />
      </nav>
    );
  }
}

class NavItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false
    };

    this.onTabMenu = this.onTabMenu.bind(this);
  }

  onTabMenu(state) {
    if(this.props.hasSubMenu) {
      this.setState({ focus: state })
    }
  }

  render(){
    const {
      children,
      onClick,
      isExact,
      hasSubMenu,
      toggleSubnav,
      to,
      type,
      title
    } = this.props;

    // sanitize the link text and make it into a url
    let url = to.replace(' ', '-').replace(/^/,'/').replace('home', '');
    let subnavToggler = null;
    let classPrefix = 'nav';
    let txt;

    if(hasSubMenu) {
      subnavToggler = <span className="nav__subnav-toggler" onClick={toggleSubnav}></span>
    }

    title ? txt = title : txt = to;

    if(type === "subnav") {
      url = to;
      classPrefix = 'subnav';
    }

    return (
      <li className={classnames(`${classPrefix}__item`, { 'nav__item--focus': this.state.focus  })}
          onFocus={() => { this.onTabMenu(true); }}
          onBlur={() => { this.onTabMenu(false); }}
          onClick={() => { this.onTabMenu(false); }}>
        <NavLink
          className={`${classPrefix}__link`}
          exact={isExact}
          activeClassName={`${classPrefix}__link--here`}
          to={url}
          onClick={onClick}>
          <span className={`${classPrefix}__text`}>{txt}</span>
        </NavLink>
        {subnavToggler}
        {children}
      </li>
    )
  }
}

export default Nav;
