import React, { Component } from 'react';
import classnames           from 'classnames';

class PageNotReady extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 1);
  }

  render() {

    const pageType = this.props.pageType;
    const errorMessage = this.props.errorMessage;
    const isComponent = this.props.isComponent;

    // loading animation
    if(pageType === 'loading' && !isComponent) {
      return (
        <div data-bg="white-grad">
          <Loading />
        </div>
      )
    }

    if(pageType === 'loading' && isComponent) {
      return (
        <Loading isComponent={isComponent} />
      )
    }

    if(pageType === 'error' && !isComponent) {
      return (

        <div data-bg="white-grad">
          <div className="page-not-found">
        		<h1>Error</h1>
        		<div className="page-not-found__visual"></div>
        		<p className="error">{errorMessage}</p>
        	</div>
        </div>
      )
    }

    if(pageType === 'error' && isComponent) {
      return (
        <div className="page-not-found page-not-found--inline">
          <h1>Error</h1>
          <p className="error">{errorMessage}</p>
        </div>
      )
    }
  }
}

class Loading extends Component {
  render() {
    const isComponent = this.props.isComponent

    return (
      <div className={classnames({ 'loading': true, 'loading--inline': isComponent })}>
        <div className="loading__cube"></div>
        <div className="loading__cube"></div>
        <div className="loading__cube"></div>
        <div className="loading__cube"></div>
      </div>
    );
  }
}

export default PageNotReady;
