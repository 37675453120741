import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Svg, Use } from '@digital-detox/react-svg-use-external';

import axios from 'axios';
import PageNotReady from './PageNotReady';
import classnames from 'classnames';
import { createMarkup, getRoot, mapboxToken } from '../utils';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { speechBubbleIcon } from '../utils';
import styleJson from '../styling/mapbox/contact.json';

mapboxgl.accessToken = mapboxToken;

class Contact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contactData: [],
      isLoading: true,
      error: null
    };

    this.loadMap = this.loadMap.bind(this);
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 1);

    const rootUrl = getRoot();

    this.axiosCancelSource = axios.CancelToken.source();
    axios.get(rootUrl + '/api/?request=contact', { cancelToken: this.source.token })
      .then(result => {
        this.setState({
          contactData: result.data[0],
          isLoading: false
        });
        this.loadMap();
      })
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  loadMap () {

    const stylesheet = styleJson;
    const lng = 4.8313;
    const lat = 52.35392;
    const pitch = 45;
    const zoom = 13;
    const icon = speechBubbleIcon;

    // import image assets and json styling file
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: stylesheet,
      center: [lng, lat],
      pitch: pitch,
      zoom: zoom
    });

    map.on('load', () => {
      const image = new Image();
      image.src = icon;

      image.onload = () => {
        map.addImage('speech-bubble', image);
        map.addLayer({
            "id": "points",
            "type": "symbol",
            "source": {
              "type": "geojson",
              "data": {
                "type": "FeatureCollection",
                "features": [{
                  "type": "Feature",
                  "geometry": {
                    "type": "Point",
                    "coordinates": [lng, lat]
                  }
                }]
              }
            },
            "layout": {
              "icon-image": "speech-bubble",
              "icon-size": 0.5
            }
          });
        };
      });

      // reposition the zoom controls and disable zooming by scroll
      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, 'bottom-right').scrollZoom.disable();
  }

  render() {

    const { contactData, isLoading, error } = this.state;

    if(isLoading) { return <PageNotReady pageType="loading" /> }
    if(error)     { return <PageNotReady pageType="error" errorMessage={error.message} /> }

    return (
      <>
        <Helmet>
          <title>Contact Bart Hendrix, pixel polisher</title>
          <meta name="description" content="Contact me via email, telephone, or check out my linkedin profile." />
        </Helmet>
        <div className="wrapper">
          <section className="pictorial pictorial--map-cover">
            <div className="pictorial__txt">
              <h1>Contact</h1>
              <div dangerouslySetInnerHTML={createMarkup(contactData.txt)} />
              <div className="vcard">
        				<h3 className="fn vcard__fn">Bart Hendrix</h3>
        				<ul  className="vcard__ul">
                  <VcardItem href="tel:+31614558221" icon="phone" text="+31 (0)6 1455 8221" />
                  <VcardItem href="mailto:info@barthendrix.com" icon="mail" text="info@barthendrix.com" />
                  <VcardItem isBlank href="https://www.linkedin.com/in/bart-hendrix-57bab241/" icon="linkedin" text="LinkedIn" />
                  <VcardItem isBlank href="https://github.com/pixelpolisher" icon="github" text="GitHub" />
        				</ul>
        				<p className="adr vcard__p">
        					<span className="street-address">Theodorus Majofskistraat 23<sup>2</sup></span><br />
        					<span className="postal-code">1065SR</span>
                  &nbsp;
        					<span className="locality">Amsterdam</span>
        				</p>
        				<p className="vcard__p">CoC / KvK: 7511 7169</p>
        			</div>
            </div>

            <div className="pictorial__visual pictorial__visual--bottom-aligned">
        		  <div className="pictorial__img pictorial__img--telephone"></div>
            </div>
          </section>
        </div>

        <div className="map">
          <div className="map__container" ref={el => this.mapContainer = el} />
        </div>
      </>
    );
  }
}

export default Contact;

class VcardItem extends Component {
  render() {
    const {
      href,
      icon,
      isBlank,
      text
    } = this.props;

    return (
      <li className="vcard__li">
        <a
          className="vcard__link"
          href={href}
          target={isBlank && "_blank"}
          rel={isBlank && "noopener noreferrer"}>
          <Svg className={classnames('vcard__svg', `vcard__svg--${icon}`)}><Use xlinkHref={`styling/img/sprite.svg#icon-${icon}`} /></Svg>
          <span>{text}</span>
        </a>
      </li>
    )
  }
}
