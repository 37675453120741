import React, { Component } from 'react';
import classnames from 'classnames';
import { Svg, Use } from '@digital-detox/react-svg-use-external';

class Footer extends Component {
  render() {
    return(
      <footer className="app__footer footer">
        <ul className="footer__menu">
          <FooterItem icon="phone" url="tel:+31614558221">+31 (6) 1455 8221</FooterItem>
          <FooterItem icon="mail" url="mailto:info@barthendrix.com">info@barthendrix.com</FooterItem>
          <FooterItem isBlank icon="linkedin" url="https://www.linkedin.com/in/bart-hendrix-57bab241/"></FooterItem>
          <FooterItem isBlank icon="github" url="https://github.com/pixelpolisher"></FooterItem>
        </ul>
      </footer>
    );
  }
}

class FooterItem extends Component {
  render(){
    const {
      children,
      icon,
      url,
      isBlank
    } = this.props;

    return (
      <li className="footer__item">
        <a
          className="footer__link"
          target={isBlank && "_blank"}
          rel={isBlank && "noopener noreferrer"}
          title={icon}
          href={url}>
          <Svg className={classnames('footer__icon', `footer__icon--${icon}`)}>
            <Use xlinkHref={`styling/img/sprite.svg#icon-${icon}`} />
          </Svg>
          <span className="footer__text">{children}</span>
        </a>
      </li>
    )
  }
}

export default Footer;
