import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

class Polaroid extends Component {

  render() {
    const {
      url,
      image,
      title,
      category,
      scrollOn,
      isPhotograph,
    } = this.props;

    if(scrollOn === true && this.polaroidRef) {
      // the 1 second delay forces ie11 to play nice
      setTimeout(() => {
        const polaroidTop = Math.round( ReactDOM.findDOMNode(this.polaroidRef).getBoundingClientRect().top - 65);
        window.scroll({
          left: 0,
          top: polaroidTop,
          behavior: 'smooth'
        });
      }, 1);
    }

    if(isPhotograph) {
      return (
        <Link
          to={`/bike-trip/pictures/${url}` }
          onClick={this.props.onClick}
          className={classnames({ 'polaroid': true, 'polaroid--photograph': true, 'polaroid--active': scrollOn })}
          ref={el => this.polaroidRef = el }>
            <div className="polaroid__frame">
              <img
                className="polaroid__image"
                src={`/images/bike-trip/gallery/sm/${image}`}
                alt={title} loading="lazy"
                width="420"
                height="315" />
            </div>
          <h5 className="polaroid__header">{title}</h5>
        </Link>
      )
    }
    else {
      return(
        <Link
          to={`/portfolio/${url}` }
          onClick={this.props.onClick}
          className={classnames({ 'polaroid': true, 'polaroid--active': scrollOn })}
          ref={el => this.polaroidRef = el }>
            <div className="polaroid__frame">
              <img
                className="polaroid__image"
                src={`/images/sm/${image}`}
                alt={title} loading="lazy"
                width="460"
                height="259" />
            </div>
          <h4 className="polaroid__header">{title}</h4>
          <h5 className="polaroid__subheader">{category}</h5>
        </Link>
      )
    }
  }
}

export default Polaroid;
